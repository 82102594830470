<template>
  <div :class="classes" @click="onInput"></div>
</template>

<script lang="ts" setup>
import { useCssModule, computed } from 'vue'
import { BASE_PROPS_DEFAULTS } from './consts'
import type { BaseProps } from './types'

defineOptions({
  name: 'UiSwitcher'
})

const props = withDefaults(defineProps<BaseProps>(), BASE_PROPS_DEFAULTS)

const emit = defineEmits<{
  input: [value: boolean]
}>()

const cssModule = useCssModule()

const classes = computed(() => [
  cssModule.container,
  { [cssModule.active]: props.value },
  { [props.themeActive]: props.value }
])

const onInput = () => {
  emit('input', !props.value)
}
</script>

<style lang="scss" module>
.container {
  width: 33px;
  height: 20px;
  display: inline-flex;
  align-items: center;
  padding: 2px;
  border-radius: 20px;
  background-color: #0f1622;
  transition: background-color 0.4s cubic-bezier(0.785, 0.135, 0.15, 1);
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    background-color: #3f475b;
    transition: transform 0.4s cubic-bezier(0.785, 0.135, 0.15, 1);
  }
}
.active {
  background-color: #00c7b1;
  &:before {
    transform: translateX(12px);
  }
}
</style>
